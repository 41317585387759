import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


const Container = styled.div`

`
const ResultContent = {
    width: '300px',
    height: '300px',
    fontSize: '16px',
    fontFamily: 'Pretendard-Regular',
    outline:"none",
    resize :"none",
    border:"none",
    padding:'20px',
    margin: '20px',
    lineHeight:1.2,
    background : "rgb(201 255 236 / 39%)",
    color:'rgb(82 83 82)',
  }


const ResultContent2 = {
    width: '300px',
    height: '300px',
    fontSize: '16px',
    fontFamily: 'Pretendard-Regular',
    outline:"none",
    resize :"none",
    border:"none",
    padding:'20px',
    margin: '20px',
    lineHeight:1.2,
    background : "#fff5c6a1",
    color:'rgb(82 83 82)',
  }


const Slick = ({noticeitems}) => {
    const settings = {
      dots: true, // 슬라이더 하단에 점을 표시
      infinite: true, // 무한 루프 설정
      speed: 500, // 슬라이더 전환 속도
      slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
      slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 수
      autoplay: true, // 자동 슬라이드
      autoplaySpeed: 2000, // 자동 슬라이드 속도 (밀리초)
      swipeToSlide: true, // 슬라이드 간 스와이프
      adaptiveHeight: true, // Adjust slider height
    };
  
    return (
     <div style={{ width: '100%', height:"300px"}}>
        <Slider {...settings}>
            {
                noticeitems.map((data, index)=>(
                    <div style={{height:"300px"}}>
                    <div style={{ height:"300px"}}>
                        {
                            index % 2 == 0  && <textarea value={data.NOTICE}  style={ResultContent}/>   
                        }
                        {
                            index % 2 != 0 && <textarea value={data.NOTICE}  style={ResultContent2}/>   
                        }
                                 
                    </div>
                </div>
                ))
            }
 
        </Slider>
      </div>
    );
  };
  
export default Slick;
  