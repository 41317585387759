import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";
import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import { imageDB } from "../utility/imageData";
import AcceptModalEx from "../components/AcceptModalEx";
import { Mobile, PC } from "../components/Responsive";

import "../screen/css/common.css";
import { LoginType } from "../utility/contentDefine";
import { auth } from "../api/config";
import { add_tax, login } from "../service/UserService";
import AuthAlert from "../components/AuthAlert";
import {
  getGovernment24Token,
  getHomeTaxToken,
  getIncome,
  getPrivacy,
  getPrivacyToken,
  getVatTax,
  getVatTax2,
  getWeTaxToken,
  getWithholdingTax,
  getYear,
  getYearToken,
} from "../utility/api";
import { AuthErroMsgFind, getDateFullTime, useSleep,IsEmpty } from "../utility/common";
import { UserContext } from "../context/User";
import { FaCircleInfo } from "react-icons/fa6";
import { BiSolidFastForwardCircle } from "react-icons/bi";



const Container = styled.div`
  padding: 80px 0px;
  background: white;
  height: ${({ height }) => height - 80}px;
`;
const PCContainer = styled.div`
width: 27%;
margin: 0px 37%;
  background: white;
  padding-top: 80px;
  height: ${({ height }) => height - 80}px;
  border :1px solid;
`;

const AuthLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  flex-wrap: wrap;
  padding: 0px 10%;
`;

const AuthButton = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #ededed;
  margin: 2px;
  background: #fafafa;
  border-radius: 10px;
`;



// 간편인증 업체에 인증 키  요청을 진행 하고 
// 인증키를 바탕으로 5초마다 인증키를 가지고 홈택스 정보인 
// 연말정산 대한 정보를 요청 한다
// 연말정산이 성공한 경우는 소득금액을 요청 사업자가 있는경우는 부가가치세, 원천세에 대한 정보를 요청한다


const Hometaxgetcontainer = ({ logintype }) => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);

  const [notice, setNotice] = useState("");
  const [notice2, setNotice2] = useState("");

  const [refresh, setRefresh] = useState(1);
  const [government24, setGovernment] = useState(false);
  const [hometax, setHometax] = useState(true);

  const [error, setError] = useState(false);
  const { user, dispatch2 } = useContext(UserContext);
  const [hometaxprocess, setHometaxprocess] = useState(0);
  const [hometaxprocessfail, setHometaxprocessfail] = useState(false);
  const [hometaxprocesscomplete, setHometaxprocesscomplete] = useState(false);



  useEffect(() => {
    setHometaxprocess(hometaxprocess);
    setHometaxprocessfail(hometaxprocessfail);
    setHometaxprocesscomplete(hometaxprocesscomplete);


  }, [refresh]);


  useEffect(() => {


    // let noticetmp = "첫번째 인증 절차입니다";
    // setNotice(noticetmp);

    let noticetmp = "홈택스로 부터 정보 수집을 위해" + logintype+ " 에 간편인증 요청을 하였습니다";
    setNotice2(noticetmp);


    async function FetchData() {
      console.log("요청합니다");

      const HometaxToken = await getHomeTaxToken({user});
      console.log("HomeTaxToken", HometaxToken);

      if ((HometaxToken.data.common.errMsg != "")) {
        setError(true);
        return;
      } else {
        user["hometax"] = HometaxToken.data.data.stepData;
        dispatch2(user);
        setHometax(true);
      }


    }
    FetchData();
  }, []);



  function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const _handlenext = async () => {

    setHometaxprocess(1);
  
    initProcess().then(()=>{
      if(IsEmpty(user.yeardata)){
        // 정보를 가져오는데 실패 햇다면
    
        setHometaxprocess(2);
        setHometaxprocessfail(true);
  
      }else{
        // 정보를 가져오는데 성공했다면
        goProcess().then(()=>{
          setHometaxprocess(2);
          setHometaxprocesscomplete(true);
        });
  

      }
    });
    



    setRefresh((refresh) => refresh +1);

  };

  const initProcess = async()=>{
    // 연말정산 정보 가져왔네
    for(let i = 0; i < 6; i++){

      console.log("연말정산 수집",getDateFullTime(Date.now()))
       year_request({user}).then(async (yearresult)=>{
 
         console.log("year_data", yearresult);
   
         if(yearresult != -1){
           user["yeardata"] = yearresult;
           dispatch2(user);
           i = 6;  
         }
       })
 
       await sleep(10000)
 
     }

     return new Promise(async (resolve, reject)=>{
      resolve(0);
     });
  }

  const goProcess = async() =>{
     //소득금액을 가져 왓네
    const incomekey = user.hometax;
    console.log("소득금액 수집",getDateFullTime(Date.now()))
    const incomedata = await getIncome({ user, incomekey });


    console.log("소득금액 data", incomedata);

    if(!AuthErroMsgFind(incomedata.data.common.errMsg)){
      user["incomedata"] = incomedata.data.data;
      dispatch2(user);
    }

    // 부가가치세를 가져왔네
    // 1번 사업자 거 가져오자
    if(user.biznum.length == 1 && user.biznum[0] != ''){
      const taxkey = user.hometax;
      const biznum = user.biznum[0];

      let vatdata = await getVatTax({ user, taxkey, biznum });
      if(!AuthErroMsgFind(vatdata.data.common.errMsg)){
        user["vatdata"] = vatdata.data.data;
        dispatch2(user);
    
      }

      vatdata = await getVatTax2({ user, taxkey, biznum });
      if(!AuthErroMsgFind(vatdata.data.common.errMsg)){
        user["vatdata4"] = vatdata.data.data;
        dispatch2(user);
      }
    }
    // 2번 사업자 거 가져오자
    if(user.biznum.length >= 2){
      const taxkey = user.hometax;
      const biznum = user.biznum[1];

      let vatdata = await getVatTax({ user, taxkey, biznum });
      if(!AuthErroMsgFind(vatdata.data.common.errMsg)){
        user["vatdata2"] = vatdata.data.data;
        dispatch2(user);
    
      }

      vatdata = await getVatTax2({ user, taxkey, biznum });
      if(!AuthErroMsgFind(vatdata.data.common.errMsg)){
        user["vatdata5"] = vatdata.data.data;
        dispatch2(user);
      }
    }
    // 3번 사업자거 가져오자
    if(user.biznum.length >=3){
      const taxkey = user.hometax;
      const biznum = user.biznum[2];

      let vatdata = await getVatTax({ user, taxkey, biznum });
      if(!AuthErroMsgFind(vatdata.data.common.errMsg)){
        user["vatdata3"] = vatdata.data.data;
        dispatch2(user);
    
      }

      vatdata = await getVatTax2({ user, taxkey, biznum });
      if(!AuthErroMsgFind(vatdata.data.common.errMsg) ){
        user["vatdata6"] = vatdata.data.data;
        dispatch2(user);
      }
    }


    // 원천징수세를 가져왓네
    // 1번 사업자 거 가져오자
    if(user.biznum.length == 1 && user.biznum[0] != ''){
      const taxkey = user.hometax;
      const biznum = user.biznum[0];

      let withholdingdata = await getWithholdingTax({ user, taxkey, biznum });
      if(!AuthErroMsgFind(withholdingdata.data.common.errMsg)){
        user["withholdingdata"] = withholdingdata.data.data;
        dispatch2(user);
    
      }
    }
    // 2번 사업자 거 가져오자
    if(user.biznum.length >= 2){
      const taxkey = user.hometax;
      const biznum = user.biznum[1];

      let withholdingdata = await getWithholdingTax({ user, taxkey, biznum });
      if(!AuthErroMsgFind(withholdingdata.data.common.errMsg)){
        user["withholdingdata2"] = withholdingdata.data.data;
        dispatch2(user);
    
      }
    }
     // 3번 사업자거 가져오자
    if(user.biznum.length >= 2){
      const taxkey = user.hometax;
      const biznum = user.biznum[2];

      let withholdingdata = await getWithholdingTax({ user, taxkey, biznum });
      if(!AuthErroMsgFind(withholdingdata.data.common.errMsg)){
        user["withholdingdata3"] = withholdingdata.data.data;
        dispatch2(user);
    
      }
    }

    return new Promise(async (resolve, reject)=>{
      resolve(0);
     });

  }


  const _handlehometaxfail = async () => {
    //todo 원래 코드
    navigate("/");

  };

  const _handlehometaxnext = async () => {
    //todo 원래 코드
    // const logintype = user.authtype;
    // navigate("/wetaxget", { state: { LOGINTYPE: logintype } });

    console.log("home tax get information", user, user.jumin);

    const username = user.name;
    const phone = user.phone;
    const jumin = user.jumin;
    const bizrealstate = user.bizrealstate;
    const bizhumancost = user.bizhumancost;
    const biznum = user.biznum;
    const bank = user.bank;
    const hometaxid = user.hometaxid;
    const hometaxpw = user.hometaxpw;
    const wetaxid = user.wetaxid;
    const wetaxpw = user.wetaxpw;

    const yeardata = user.yeardata;
    const incomedata = user.incomedata;
    const vatdata =user.vatdata;
    const vatdata2 = user.vatdata2;
    const vatdata3 = user.vatdata3;
    const vatdata4 = user.vatdata4;
    const vatdata5 = user.vatdata5;
    const vatdata6 = user.vatdata6;
    const withholdingdata = user.withholdingdata;
    const withholdingdata2 = user.withholdingdata2;
    const withholdingdata3 = user.withholdingdata3;
    const localtaxdata = user.localtaxdata;


  const addtax = await add_tax({
    username,
    phone,
    jumin,
    bank,
    hometaxid,
    hometaxpw,
    wetaxid,
    wetaxpw,
    biznum,
    bizrealstate,
    bizhumancost,
    yeardata,
    incomedata,
    vatdata,
    vatdata2,
    vatdata3,
    vatdata4,
    vatdata5,
    vatdata6,
    withholdingdata,
    withholdingdata2,
    withholdingdata3,
    localtaxdata,
  });




    navigate("/loading");

  };

  return (
    <>
      <Mobile>
        <Container height={800}>
          {/* <Guide main1={notice}></Guide> */}
          <Guide main1={notice2}></Guide>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 16,
              padding: 20,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "left",
              lineHeight: 2,
              marginTop:20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCircleInfo />
              <div style={{ marginLeft: 10 }}>인증전 꼭 확인해주세요</div>
            </div>

            <div>고객님 명의 스마트폰에 앱이 설치되어 있어야합니다</div>

            <div>
               모바일에서 {logintype}로 인증을 완료하신경우 아래 인증완료 버튼을 클릭해주세요
            </div>
          </div>
          {/* {hometax == true && (
            <div>
              <AuthAlert
                logintype={logintype}
                agency={"홈택스"}
                containerStyle={{
                  border: "1px solid #ededed",
                  background: "#fdf9f9",
                  height: "80px",
                  position: "absolute",
                  top: "60%",
                  left: "5%",
                  width: "300px",
                  zIndex: 5,
                }}
              />
            </div>
          )} */}
          {/* {wetax == true && (
            <div>
              <AuthAlert
                logintype={logintype}
                agency={"위택스"}
                containerStyle={{
                  border: "1px solid #ededed",
                  background: "#fdf9f9",
                  height: "80px",
                  position: "absolute",
                  top: "40%",
                  left: "5%",
                  width: "300px",
                  zIndex: 5,
                }}
              />
            </div>
          )} */}

          {error == true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Button
                buttonText={"정보가 불일치 합니다 이전단계로 이동"}
                callback={_handlehometaxfail}
                containerStyle={{
                  backgroundColor: "#cccbc9",
                  color: "#000",
                  margin: "10px",
                  width: "100%",
                  height: 50,
                  fontSize: 16,
                  fontWeight: 700,
                  borderRadius: "10px",
                }}
              />
            </div>
          ) : (
            <>
              {hometax == true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  {(logintype == LoginType.kakao && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
         
                      }}
                    />
                  )}
                  {(logintype == LoginType.samsung && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.pass && hometaxprocess == 0 )&& (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.payco && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}

                  {(logintype == LoginType.kb && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {hometaxprocess == 1 && (
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItem:"center"}}>
                    
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItem:"center"}}>
                    <img src={imageDB.loading} style={{ width: "100px" }} />
                    </div>
                 
                    <Button
                      buttonText={"인증 결과 조회 대기중입니다"}
                      callback={()=>{}}
                      containerStyle={{
                        backgroundColor: '#f9f9f9',
                        color: '#000',
                        height: '60px',
                        fontSize: '14px',
                        borderRadius: '10px'
                      }}
                    />
                    </div>

                  )}
                  {hometaxprocessfail == true && (
                  <>
                    <Button
                      buttonText={"홈택스에서 정보를 가져올수가 없습니다"}
                      callback={_handlehometaxfail}
                      containerStyle={{
                        backgroundColor: "#ffc247",
                        color: "#000",
                        margin: "10px",
                        width: "100%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                      }}
                    />
                  </>
                  )}
                  {hometaxprocesscomplete == true && (
                  <>

                  <Button
                    buttonText={"정상적으로 수집하였습니다 클릭해주세요"}
                    callback={_handlehometaxnext}
                    containerStyle={{
                      backgroundColor: "#ffc247",
             
                      margin: "10px",
                      width: "100%",
                      height: 60,
                      fontSize: 16,
                      fontWeight: 700,
                      borderRadius: "10px",
                    }}
                  />
                  </>
                  )}
                </div>
              )}
            </>
          )}
        </Container>
      </Mobile>
      <PC>


        <PCContainer height={height}>
          <Guide main1={notice}></Guide>
          <Guide main1={notice2}></Guide>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: 16,
              padding: 20,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "left",
              lineHeight: 2,
              marginTop:20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCircleInfo />
              <div style={{ marginLeft: 10 }}>인증전 꼭 확인해주세요</div>
            </div>

            <div>고객님 명의 스마트폰에 앱이 설치되어 있어야합니다</div>

            <div>
               모바일에서 {logintype}로 인증을 완료하신경우 아래 인증완료 버튼을 클릭해주세요
            </div>
          </div>
          {/* {hometax == true && (
            <div>
              <AuthAlert
                logintype={logintype}
                agency={"홈택스"}
                containerStyle={{
                  border: "1px solid #ededed",
                  background: "#fdf9f9",
                  height: "80px",
                  position: "absolute",
                  top: "65%",
                  left: "38%",
                  width: "300px",
                  zIndex: 5,
                }}
              />
            </div>
          )} */}
          {/* {wetax == true && (
            <div>
              <AuthAlert
                logintype={logintype}
                agency={"위택스"}
                containerStyle={{
                  border: "1px solid #ededed",
                  background: "#fdf9f9",
                  height: "80px",
                  position: "absolute",
                  top: "40%",
                  left: "5%",
                  width: "300px",
                  zIndex: 5,
                }}
              />
            </div>
          )} */}

          {error == true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "27%",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Button
                buttonText={"정보가 불일치 합니다 이전단계로 이동"}
                callback={_handlehometaxfail}
                containerStyle={{
                  backgroundColor: "#cccbc9",
                  color: "#000",
                  margin: "10px",
                  width: "95%",
                  height: 50,
                  fontSize: 16,
                  fontWeight: 700,
                  borderRadius: "10px",
                }}
              />
            </div>
          ) : (
            <>
              {hometax == true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "27%",
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  {(logintype == LoginType.kakao && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
         
                      }}
                    />
                  )}
                  {(logintype == LoginType.samsung && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.pass && hometaxprocess == 0 )&& (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {(logintype == LoginType.payco && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ff2121",
                        color: "#fff",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}

                  {(logintype == LoginType.kb && hometaxprocess == 0) && (
                    <Button
                      buttonText={logintype + " 인증 완료후 클릭해주세요"}
                      callback={_handlenext}
                      containerStyle={{
                        backgroundColor: "#ffc83e",
                        color: "#000",
                        margin: "30px 10x",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                        fontFamily :"Pretendard-Bold"
                      }}
                    />
                  )}
                  {hometaxprocess == 1 && (
                       <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItem:"center"}}>
                    
                       <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItem:"center"}}>
                       <img src={imageDB.loading} style={{ width: "100px" }} />
                       </div>
                    
                       <Button
                         buttonText={"인증 결과 조회 대기중입니다"}
                         callback={()=>{}}
                         containerStyle={{
                           backgroundColor: '#f9f9f9',
                           color: '#000',
                           height: '60px',
                           fontSize: '14px',
                           borderRadius: '10px'
                         }}
                       />
                       </div>
                  )}
                  {hometaxprocessfail == true && (
                  <>
                    <Button
                      buttonText={"홈택스에서 정보를 가져올수가 없습니다"}
                      callback={_handlehometaxfail}
                      containerStyle={{
                        backgroundColor: "#ffc247",
                        color: "#000",
                        margin: "10px",
                        width: "95%",
                        height: 50,
                        fontSize: 16,
                        fontWeight: 700,
                        borderRadius: "10px",
                      }}
                    />
                  </>
                  )}
                  {hometaxprocesscomplete == true && (
                  <>

                  <Button
                    buttonText={"정상적으로 수집하였습니다 클릭해주세요"}
                    callback={_handlehometaxnext}
                    containerStyle={{
                      backgroundColor: "#ffc247",
             
                      margin: "10px",
                      width: "95%",
                      height: 60,
                      fontSize: 16,
                      fontWeight: 700,
                      borderRadius: "10px",
                    }}
                  />
                  </>
                  )}
                </div>
              )}
            </>
          )}
        </PCContainer>
      </PC>
    </>
  );
};

export default Hometaxgetcontainer;



  //연말신청
export const year_request = async ({user}) =>{

  let yeardata = {};
  return new Promise(async (resolve, reject)=>{

    async function FetchData() {

      const yearkey = user.hometax;
      const yeardata = await getYear({ user, yearkey });

      console.log("hometax get data year data", yeardata);

      if (AuthErroMsgFind(yeardata.data.common.errMsg)) {
      
        console.log("인증실패");
        resolve(-1);
      
      } else {
        if (yeardata.data.common.errMsg != "") {
          console.log("데이타수집실패");
          resolve(-1);
   
        } else {
          console.log("데이타수집성공", yeardata.data.data);


      
     
          resolve(yeardata.data.data);
        }
      }
    }
    FetchData();
})


}
