import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import Label from "../common/Label";
import ServiceItem from "../components/ServiceItem";
import ServiceItem2 from "../components/DocumentItem";
import { Mobile, PC } from "../components/Responsive";
import HumanItem from "../components/HumanItem";
import YearItem from "../components/YearItem";
import FinancialItem from "../components/IncomeItem";
import VatItem from "../components/VatItem";
import TaxItem from "../components/LocalTaxItem";
import { imageDB } from "../utility/imageData";
import {
  get_tax,
  Update_useragentcheck,
  update_userfile,
  update_usernote,
  update_userrequest,
  Update_usersinglecheck,
  Update_userwomancheck,
} from "../service/UserService";
import { UserContext } from "../context/User";
import { Alarm, AttachType, InfoType } from "../utility/contentDefine";
import { CommaFormatted, IsEmpty, JuminConvert, useSleep } from "../utility/common";
import ServiceItem3 from "../components/ServiceItem3";
import WidthhodlingItem from "../components/WithholdingItem";
import ContentItem from "../components/ContentItem";
import DocumentItem from "../components/DocumentItem";
import RequestItem from "../components/RequestItem";
import ResultItem from "../components/ResultItem";
import MainButton from "../common/MainButton";
import { FaCheck } from "react-icons/fa6";
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import { nodeModuleNameResolver } from "typescript";

const Container = styled.div`
  padding-top: 30px;
  background: white;
`;
const PCContainer = styled.div`
  width: 27%;
  margin: 0px 37%;
  background: white;
  padding-top: 35px;
  border : 1px solid
`;
const ServiceLayer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0px;
`;
const LabelItem = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  font-size:14px;
  font-family:Pretendard-Bold;
`
const TableLayer = styled.div`
  padding:10px;
  font-size:9px;
`
const TableTr = styled.tr`
  height:40px;
`

const SubLabel = styled.div`
  background: #ededed;
`

const InitContainer = styled.div``;

const NewHomecontainer = ({}) => {
  const navigate = useNavigate();
  const [tax, setTax] = useState({});
  const [humandata, setHumandata] = useState("");
  const [humaninfo, setHumaninfo] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);
  const { user, dispatch2 } = useContext(UserContext);
  const [note, setNote] = useState("");
  const [status, setStatus] = useState("init");

  const [incomemoney, setIncomemoney] = useState(0);
  const [vatcount, setVatcount] = useState(0);
  const [withholdingmoney, setWithholdingmoney] = useState(0);
  const [localmoney, setLocalmoney] = useState(0);

  const [agentcheck, setAgentcheck] = useState(true);
  const [womancheck, setWomancheck] = useState(tax.woman);
  const [singlecheck, setSinglecheck] = useState(tax.single);

  const [etchumanitems, setEtchumanitems] = useState([]);



  const [reload, setReload] = useState(0);
  const items = [
    {
      label:
        "2024년 종합소득세 신고 기간입니다. 신고는 4월 20일까지 완료 되어야 합니다",
      background: "#fef0d3",
      img: imageDB.maingbg6,
      width: "150px",
      button: true,
    },
    {
      label: "2025년 종합소득세 신고를 빠르게 도와드립니다",
      background: "#f1f8ff",
      img: imageDB.maingbg3,
      width: "150px",
      height: "100px",
      button: true,
    },
    {
      label:
        "기존의 번거로운 세무자료를 직접 할 필요가 없습니다 세금신고 자료를 보내주세요",
      background: "#f5f6f8",
      img: imageDB.maingbg4,
      width: "150px",
      height: "100px",
      button: true,
    },
    {
      label: "공제 대상 자료는 다음에서 확인해주세요",
      background: "#f5f6f8",
      img: imageDB.maingbg1,
      width: "150px",
      height: "100px",
      button: true,
    },
  ];

  const _handlehuman = () => {};

  const _handleYearend = () => {};
  const _handlerequest2 = () =>{};
  const _handlerequest = async () => {
    console.log("note", note);

    const tax_id = tax.tax_id;

    const u = await update_userrequest({ tax_id });

    const u2 = await useSleep(500);

    setStatus("request");

    setRefresh((refresh) => refresh + 1);

    // async function FetchData() {
    //   setLoading(true);
    //   let phone_ = user.phone.replaceAll("-", "");

    //   const phonedata = phone_;

    //   const username = user.name;

    //   useSleep(1000);

    //   const taxitem = await get_tax({ username, phonedata });
    //   setTax(taxitem);
    //   setNote(taxitem.note);

    //   setStatus(taxitem.status);
    //   setLoading(false);

    //   setRefresh((refresh) => refresh + 1);
    // }
    // FetchData();
  };

 
  useEffect(() => {
    // setHumaninfo(humaninfo);
    setTax(tax);
    setNote(note);
    setStatus(status);

    setAgentcheck(agentcheck);
    setWomancheck(womancheck);
    setSinglecheck(singlecheck);

    console.log("tax", tax);
  }, [refresh]);

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function FetchData() {
      setLoading(true);

      if (user.name != "") {
        const userObjString = JSON.stringify(user);
        window.localStorage.setItem("user", userObjString);
      } else {
        const userObj = JSON.parse(window.localStorage.getItem("user"));
      
        if (userObj != undefined) {
          user.name = userObj.name;
          user.phone = userObj.phone;


          dispatch2(user);
        }
      }
      
      
      let phone_ = user.phone.replaceAll("-", "");

      const phonedata = phone_;

      const username = user.name;

      console.log("user information", user);
     
 

      

      const taxitem = await get_tax({ username, phonedata });

      if (!IsEmpty(taxitem)) {
        setTax(taxitem);
        setEtchumanitems(taxitem.etchumandata);

        if (taxitem) {
          setNote(taxitem.note);
          setStatus(taxitem.status);

          let incomemoneydata = 0;

          if (!IsEmpty(taxitem.incomedata)) {
            taxitem.incomedata.list1.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list2.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list3.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list4.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });

            setIncomemoney(incomemoneydata);
          }

          let vatcount = 0;
          if (!IsEmpty(taxitem.vatdata) || !IsEmpty(taxitem.vatdata4)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata2) || !IsEmpty(taxitem.vatdata5)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata3) || !IsEmpty(taxitem.vatdata6)) {
            vatcount += 1;
          }

          setVatcount(vatcount);

          let withholdingmoneydata = 0;

          if (!IsEmpty(taxitem.withholdingdata)) {
            taxitem.withholdingdata.map((data, index) => {
              withholdingmoneydata += parseInt(data.incPymnAmt);
            });

            setWithholdingmoney(withholdingmoneydata);
          }
          let localmoney = 0;

          if (!IsEmpty(taxitem.localtaxdata)) {
            taxitem.localtaxdata.list.map((data, index) => {
              localmoney += parseInt(data.sumAmt);
            });

            console.log("localtax", taxitem.localtaxdata);

            setLocalmoney(localmoney);
          }
          
  
        }
      }

      setLoading(false);

      setRefresh((refresh) => refresh + 1);
    }
    FetchData();
  }, []);

  useEffect(() => {
    async function FetchData() {
      setLoading(true);
      let phone_ = user.phone.replaceAll("-", "");

      const phonedata = phone_;

      const username = user.name;

      const taxitem = await get_tax({ username, phonedata });

      if (!IsEmpty(taxitem)) {
        setTax(taxitem);
        if (taxitem) {
          setNote(taxitem.note);
          setStatus(taxitem.status);

          let incomemoneydata = 0;

          if (!IsEmpty(taxitem.incomedata)) {
            taxitem.incomedata.list1.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list2.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list3.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });
            taxitem.incomedata.list4.map((data, index) => {
              incomemoneydata += parseInt(data.totaPymnAmt);
            });

            setIncomemoney(incomemoneydata);
          }

          let vatcount = 0;
          if (!IsEmpty(taxitem.vatdata) || !IsEmpty(taxitem.vatdata4)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata2) || !IsEmpty(taxitem.vatdata5)) {
            vatcount += 1;
          }
          if (!IsEmpty(taxitem.vatdata3) || !IsEmpty(taxitem.vatdata6)) {
            vatcount += 1;
          }

          setVatcount(vatcount);

          let withholdingmoneydata = 0;

          if (!IsEmpty(taxitem.withholdingdata)) {
            taxitem.withholdingdata.map((data, index) => {
              withholdingmoneydata += parseInt(data.incPymnAmt);
            });

            setWithholdingmoney(withholdingmoneydata);
          }
          let localmoney = 0;

          if (!IsEmpty(taxitem.localtaxdata)) {
            taxitem.localtaxdata.list.map((data, index) => {
              localmoney += parseInt(data.sumAmt);
            });

            console.log("localtax", taxitem.localtaxdata);

            setLocalmoney(localmoney);
          }
        }
      }

      setLoading(false);

      setRefresh((refresh) => refresh + 1);
    }
    FetchData();
  }, [reload]);

  const refreshcallback = () =>{
    setStatus('init');
    setRefresh((refresh) => refresh +1);
  }

  const _handlejumin = () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.JUMIN, TAX:tax } });
  }

  const _handlebank = () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.BANK, TAX:tax } });
  }

  const _handleemail= () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.EMAIL, TAX:tax } });
  }
  const _handlehometaxid= () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.HOMETAXID, TAX:tax } });
  }

  const _handlehometaxpw= () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.HOMETAXPW, TAX:tax } });
  }

  const _handleaddr= () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.ADDR, TAX:tax } });
  }
  const _handlehouse = () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.HOUSE, TAX:tax } });
  }
  const _handlebusiness = () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.BUSINESS, TAX:tax } });
  }
  const _handlefinancial = () =>{
    navigate("/info", { state: { INFOTYPE: InfoType.FINANCIAL, TAX:tax } });
  }

  const _handleagentcheck = async() =>{
    setAgentcheck(true);
    const tax_id = tax.tax_id;
    const agentcheck = true;
    await Update_useragentcheck({tax_id, agentcheck});
    setRefresh(refresh => refresh +1);
  }

  const _handleagentuncheck = async() =>{
    setAgentcheck(false);
    const tax_id = tax.tax_id;
    const agentcheck = false;
    await Update_useragentcheck({tax_id, agentcheck});
    setRefresh(refresh => refresh +1);
  }

  const _handlewomancheck = async() =>{
    setWomancheck(true);
    const tax_id = tax.tax_id;
    const womancheck = true;
    await Update_userwomancheck({tax_id, womancheck});
    setRefresh(refresh => refresh +1);
  }
  const _handlewomanuncheck = async() =>{
    setWomancheck(false);
    const tax_id = tax.tax_id;
    const womancheck = false;
    await Update_useragentcheck({tax_id, womancheck});
    setRefresh(refresh => refresh +1);
  }

  const _handlesinglecheck = async() =>{
    setSinglecheck(true);
    const tax_id = tax.tax_id;
    const singlecheck = true;
    await Update_usersinglecheck({tax_id, singlecheck});
    setRefresh(refresh => refresh +1);
  }
  const _handlesingleuncheck = async() =>{
    setSinglecheck(false);
    const tax_id = tax.tax_id;
    const singlecheck = false;
    await Update_usersinglecheck({tax_id, singlecheck});
    setRefresh(refresh => refresh +1);
  }

  const handleMemberAdd = () =>{
    navigate("/humanregister");
  }

  const _handleincome = () =>{

    const type="income"
    const data= tax.incomedata;
 


     navigate("/tableinfo", {
       state: { type: type, data: data },
     }); 
  }
  const _handlevat = () =>{

    const type="vattax"
    const data= tax;
 
     navigate("/tableinfo", {
       state: { type: type, data: data },
     }); 
  }

  const _handleyeardata = () =>{

   const type="year"
   const data= tax.yeardata.info;

    navigate("/tableinfo", {
      state: { type: type, data: data },
    }); 
  }
  const _handlelocaltaxdata = () =>{

    const type="localtax"
    const data= tax.localtaxdata;
 
     navigate("/tableinfo", {
       state: { type: type, data: data },
     }); 
   }


   const _handlegetlocaltaxdata = () =>{
    const taxid= tax.tax_id;
     navigate("/authselect2", { state: { taxid: taxid} });

   }
  const _handletype2 = () =>{

    const attachtype = AttachType.TYPE2;
    const taxid= tax.tax_id;
 
     navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
   }

   const _handletype4 = () =>{

    const attachtype = AttachType.TYPE4;
    const taxid= tax.tax_id;
 
     navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
   }

   const _handletype11 = () =>{

    const attachtype = AttachType.TYPE11;
    const taxid= tax.tax_id;
 
     navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
   }

   const _handletype5 = () =>{

    const attachtype = AttachType.TYPE5;
    const taxid= tax.tax_id;
 
    navigate("/excelinfo", { state: { type: attachtype, taxid: taxid } });
   }

   const _handletype6 = () =>{

    const attachtype = AttachType.TYPE6;
    const taxid= tax.tax_id;
 
    navigate("/excelinfo", { state: { type: attachtype, taxid: taxid } });
   }

   const _handletype7 = () =>{

    const attachtype = AttachType.TYPE7;
    const taxid= tax.tax_id;
 
    navigate("/excelinfo", { state: { type: attachtype, taxid: taxid } });


   }

   const _handletype10 = () =>{

    const attachtype = AttachType.TYPE10;
    const taxid= tax.tax_id;
 
     navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
   }

  const _handletype9 = () =>{

    const attachtype = AttachType.TYPE9;
    const taxid= tax.tax_id;
    navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
 
   }

   const _handletype1 = () =>{

    const attachtype = AttachType.TYPE1;
    const taxid= tax.tax_id;
    navigate("/imageinfo", { state: { type: attachtype, taxid: taxid } });
 
   }


  return (
    <>
      <Mobile>
        {loading == false && (
          <Container>
            {status == "init" ? (
              <InitContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems:'center',
                    padding: "0px 10%",
                    marginTop: "40px",
                  }}
                >
                  <Label
                    content={
                      "세무대리 신청서"
                    }
                    fontsize={"20px"}
                    fontweight={700}
                  />

                  <Label
                    content={
                      "자료 누락 오류 방지를 위해 작성하는 것입니다."
                    }
                    fontsize={"12px"}
                  />


                </div>

                <LabelItem>
                ■ 신청인 정보
                </LabelItem>

                <TableLayer>
                    <table style={{width:'100%'}}>
                      <TableTr>
                        <td style={{background:"#ededed"}}>납세자성명</td>
                        <td colspan ='4'>
                        {tax.username}
                        </td>
             
                        <td style={{background:"#ededed"}}>주민등록상 <br/>주소지</td>
                        <td colspan ='3'>
                          {
                            IsEmpty(tax.addr) == true ? (
                              <MainButton
                              bgcolor={'#e3dfdf'}
                              bordercolor={'#ff0000'}
                              buttonText={'필수입력'}
                              callback={_handleaddr}
                            />
                            ) :(
                              <MainButton
                              buttonText={tax.addr +' '+tax.subaddr}
                              callback={_handleaddr}
                            />
                            )
                          }
                     
                        </td>
                      </TableTr>
                      <TableTr>
                        <td style={{background:"#ededed"}}>주민등록번호</td>
                        <td colspan ='4'>
                           {JuminConvert(tax.jumin)}

                        </td>
                        <td style={{background:"#ededed"}}>계좌번호<br/>(환급)</td>
                        <td colspan ='3'>
                
                        {
                          IsEmpty(tax.bank) == true ? (
                            <MainButton
                            bgcolor={'#e3dfdf'}
                            bordercolor={'#ff0000'}
                            buttonText={'필수입력'}
                            callback={_handlebank}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.bank}
                            callback={_handlebank}
                          />
                          )
                        }

                        </td>
                      </TableTr>
                      <TableTr>
                        <td style={{background:"#ededed"}}>핸드폰번호</td>
                        <td colspan ='4'>
                        {tax.phone}
                        </td>
                        <td style={{background:"#ededed"}}>이메일주소<br/>(선택)</td>
                        <td colspan ='3'>
                  
                           
                        {
                          IsEmpty(tax.email) == true ? (
                            <MainButton
                            bgcolor={'#f6f3f3'}
                            bordercolor={'#ff0000'}
                            buttonText={'선택입력'}
                            callback={_handleemail}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.email}
                            callback={_handleemail}
                          />
                          )
                        }

                        </td>
                      </TableTr>
                      <TableTr>
                        <td colspan ='2' style={{background:"#ededed"}}>홈택스정보</td>
                        <td style={{background:"#ededed"}}>아이디</td>
                        <td colspan ='3' >
               

                        {
                          IsEmpty(tax.hometaxid) == true ? (
                            <MainButton
                            bgcolor={'#f6f3f3'}
                            bordercolor={'#ff0000'}
                            buttonText={'선택입력'}
                            callback={_handlehometaxid}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.hometaxid}
                            callback={_handlehometaxid}
                          />
                          )
                        }

                        </td>
                        <td style={{background:"#ededed"}}>PW</td>
                        <td colspan ='2'>
                        {
                          IsEmpty(tax.hometaxpw) == true ? (
                            <MainButton
                            bgcolor={'#f6f3f3'}
                            bordercolor={'#ff0000'}
                            buttonText={'선택입력'}
                            callback={_handlehometaxpw}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.hometaxpw}
                            callback={_handlehometaxpw}
                          />
                          )
                        }
                        </td>            
                      </TableTr>
                    </table>

                </TableLayer>

                
                <LabelItem>
                ■ 수입금액 정보
                </LabelItem>
                <div style={{fontSize:9,paddingLeft:20,display:'flex'}}>(소득종류별 연간 수입금액 확인) (일용직, 퇴직, 양도소득은 종합소득세 신고대상 아님)</div>

                <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed"}} colSpan={2}>소득의 종류</td>
                        <td style={{background:"#ededed", width:80}}>세전 총수입금액</td>
                        <td style={{background:"#ededed"}}>비고</td>
                      </TableTr>

                      {/* <tr >
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='3'>홈택스조회</td>
                        <td style={{background:"#ededed"}} >직접입력</td>
                        {
                          agentcheck == true ? (<td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircle onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircleOutline onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                 <MainButton
                                  containerStyle={{height:20}}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                 />
                            
                            ) : (<div>없음</div>)} 
                            </td>):(
                            <td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircleOutline  onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircle onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                  <MainButton
                                  containerStyle={{height:20}}
                                  bgcolor={'#e3dfdf'}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                  />
                            ) : (<div>없음</div>)}           
                            </td>
                          )
                        }

                        <td  rowspan='2'>원천징수영수증</td>
                        <td  rowspan='2'>동의시 당사무소에서 대신 조회해드립니다.</td>
                      </tr>
                      <tr >
                   
                        <td style={{background:"#ededed"}}>근로소득<br/>연금소득</td>
       
                      </tr> */}
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='4'>홈택스조회</td>
                        <td style={{background:"#ededed"}}>프리랜서수입(3.3%)</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'홈텍스자료조회분'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>
               
                        <td>사업소득, 기타소득, 원천징수 영수증<br/> 총수입금액</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed"}}>정규직수입(4대보험)</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'홈텍스자료조회분'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>

                        <td>직장 정규직 근로소득 <br/>총수입금액(연말정산)</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed"}}>연금소득</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'홈텍스자료조회분'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>
                    
                        <td>과세대상 연금소득</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed"}}>사업자등록 매출</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'홈텍스자료조회분'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>
             
                        <td>사업자등록분 총수입금액</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='3'>직접입력</td>
                        <td style={{background:"#ededed"}}>주택임대소득</td>
                        <td>
                        {IsEmpty(tax.businessetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.businessetc) +'원'}
                            callback={_handlebusiness}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'직접입력'}
                            bgcolor={'#e3dfdf'}
                            bordercolor={'#ff0000'}
                            callback={_handlebusiness}
                          />)}
             
                        </td>
                  
                        <td>

                          <div>2천만원 이하 주택임대소득</div>

                        </td>
                      </tr>
                      
                      <tr style={{height:35}}>
                   
                        <td style={{background:"#ededed"}}>금융소득<br/>(2천만원 이상만)</td>
                        <td>

                        {IsEmpty(tax.houseetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.houseetc) +'원'}
                            callback={_handlehouse}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'직접입력'}
                            bgcolor={'#e3dfdf'}
                            bordercolor={'#ff0000'}
                            callback={_handlehouse}
                          />)}

                        </td>
                
                        <td>금융소득 종합과세 대상자만 입력</td>
                      </tr>
                      <tr style={{height:35}}>      
                      <td style={{background:"#ededed"}}>해외 등 기타 <br/>미신고 수입</td>
                      <td>

                      {IsEmpty(tax.houseetc) == false ? (<MainButton
                          containerStyle={{height:20}}
                          buttonText={CommaFormatted(tax.houseetc) +'원'}
                          callback={_handlehouse}
                        />):(<MainButton
                          containerStyle={{height:20}}
                          buttonText={'직접입력'}
                          bgcolor={'#e3dfdf'}
                          bordercolor={'#ff0000'}
                          callback={_handlehouse}
                        />)}

                      </td>
        
                      <td>조회 되지 않는 미신고 소득,<br/> 누락 소득, 해외 소득등 </td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed",minWidth: 20}} colspan='2'>총 수입금액등 합계</td>
                        <td></td>
                        
                      </tr>
           
                </table>
                </TableLayer>
                {/* <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed"}} colSpan={2}>소득의 종류</td>
                        <td style={{background:"#ededed", width:80}}>세전 총수입금액</td>
                        <td style={{background:"#ededed"}}>필요서류</td>
                        <td style={{background:"#ededed"}}>비고</td>
                      </TableTr>

                      <tr >
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='3'>원천<br/> 징수</td>
                        <td style={{background:"#ededed"}} >사업(3.3%)<br/>기타 소득</td>
                        {
                          agentcheck == true ? (<td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircle onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircleOutline onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                 <MainButton
                                  containerStyle={{height:20}}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                 />
                            
                            ) : (<div>없음</div>)} 
                            </td>):(
                            <td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircleOutline  onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircle onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                  <MainButton
                                  containerStyle={{height:20}}
                                  bgcolor={'#e3dfdf'}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                  />
                            ) : (<div>없음</div>)}           
                            </td>
                          )
                        }

                        <td  rowspan='2'>원천징수영수증</td>
                        <td  rowspan='2'>동의시 당사무소에서 대신 조회해드립니다.</td>
                      </tr>
                      <tr >
                   
                        <td style={{background:"#ededed"}}>근로소득<br/>연금소득</td>
           
       
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed"}}>금융소득<br/>(2천만원이상)</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>
                        <td style={{background:"#ededed"}}>금융소득내역서</td>
                        <td>홈택스 조회 또는 <br/>금융기관에서 발급</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='2'>기타</td>
                        <td style={{background:"#ededed"}}>사업자등록한 분</td>
                        <td>
                        {IsEmpty(tax.businessetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.businessetc) +'원'}
                            callback={_handlebusiness}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlebusiness}
                          />)}
             
                        </td>
                        <td style={{background:"#ededed"}}>부가세신고서/면세현황신고서</td>
                        <td>

                        { vatcount > 0 ? (
                    
                          <MainButton
                          containerStyle={{height:20}}
                          bgcolor={'#e3dfdf'}
                          buttonText={vatcount +"개의 사업장에서 부가가치세확인"}
                          callback={_handlevat}
                          />

                        ) : (<div>없음</div>)}

                        </td>
                      </tr>
                      <tr style={{height:35}}>
                   
                        <td style={{background:"#ededed"}}>주택임대소득</td>
                        <td>

                        {IsEmpty(tax.houseetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.houseetc) +'원'}
                            callback={_handlehouse}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlehouse}
                          />)}

                        </td>
                        <td style={{background:"#ededed"}}>※ 2023년 1월~12월총액</td>
                        <td>세액감면신청 혹은 3주택이상인 분만 별도 신청서 작성</td>
                      </tr>
           
                </table>
                </TableLayer> */}

                <LabelItem>
                ■ 공제대상 부양가족만 기재
                </LabelItem>
                <div style={{fontSize:9,paddingLeft:20, display:"flex", flexDirection:"row", justifyContent:'space-between', alignItems:"center"}}>
                  <div style={{ display:"flex", flexDirection:"row", justifyContent:'center', alignItems:"center"}}>
                  {
                    womancheck == true ? (<>※ 부녀자공제 신청 여(<IoIosCheckmarkCircle  onClick={_handlewomancheck}  />), 부(<IoIosCheckmarkCircleOutline onClick={_handlewomanuncheck} />)</>):(
                      <> ※ 부녀자공제 신청 여(<IoIosCheckmarkCircleOutline  onClick={_handlewomancheck}  />), 부(<IoIosCheckmarkCircle onClick={_handlewomanuncheck} />)</>

                    )
                  }
                  <div style={{width:20}}></div>

                  {
                    singlecheck == true ? (<>※ 한부모공제 신청 여(<IoIosCheckmarkCircle  onClick={_handlesinglecheck}  />), 부(<IoIosCheckmarkCircleOutline onClick={_handlesingleuncheck} />)</>):(
                      <> ※ 한부모공제 신청 여(<IoIosCheckmarkCircleOutline  onClick={_handlesinglecheck}  />), 부(<IoIosCheckmarkCircle onClick={_handlesingleuncheck} />)</>

                    )
                  }

                  </div>
 
                  <Button
                    icon={"useradd"}
                    buttonText={"공제대상	추가"}
                    callback={handleMemberAdd}
                    containerStyle={{
                      margin: "0px 10px 0px",
                      width: "60px",
                      height: 20,
                      fontSize: 10,
                      fontWeight: 300,
                      backgroundColor: "#ededed",
                      color: "#757575",
                      borderRadius: "5px",
                      border : 'none',
                      display: "flex",
                      boxShadow: '2px 1px 1px #e2dfdf',
                      justifyContent: "space-around",
                    }}
                  />

                </div>
                <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed", width:100}}>성명</td>
                        <td style={{background:"#ededed", width:100}}>주민등록번호</td>
                        <td style={{background:"#ededed"}}>본인과의관계</td>
                        <td style={{background:"#ededed"}}>장애여부</td>
                      </TableTr>

                      {
                        etchumanitems.map((data,index)=>(
                          <tr style={{height:25}}>
                          <td>{data.name}</td>
                          <td>{JuminConvert(data.jumin)}</td>
                          <td>{data.relation}</td>
                          <td>{data.disable}</td>
                        </tr>
                        ))
                      }

                      {
                        10  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }

                      {
                        9  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        8  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        7  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        6  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        5  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        4  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        3  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
              



           
                </table>
                </TableLayer>
                <div style={{fontSize:9, textAlign:"left",paddingLeft:15}}>
                * 공제대상 부양가족 : 연간소득 100만 원 이하의 1963.12.31.이전 출생 양가 부모(동거무관) 및 2003.01.01.이후 출생 직계비속과 형제
                </div>
                <div style={{fontSize:9, textAlign:"left",paddingLeft:15, paddingTop:10}}>
                * 부녀자공제: 배우자있는여성또는부양가족있는여성세대주로연소득3천만원이하 *한부모공제: 배우자없고직계비속만있는분
                </div>

                <LabelItem>
                ■ 연말정산간소화 자료, 사업성 필요경비 등 개인 서류
                </LabelItem>


                <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed", width:100}}>서류종류</td>
                        <td style={{background:"#ededed", width:30}}>구분</td>
                        <td style={{background:"#ededed", width:90}}>첨부여부</td>
                        <td style={{background:"#ededed"}}>준비경로</td>
                      </TableTr>
                      <tr style={{height:40}}>
                        <td>연말정산간소화자료</td>
                        <td><div style={{fontFamily:'Pretendard-Bold'}}>필수</div></td>
                        <td>
                        {IsEmpty(tax.yeardata.info) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'홈택스자료있음'}
                            callback={_handleyeardata}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handleyeardata}
                          />)}
                        </td>
                        <td>국세청 홈택스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>차량보험증권</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type2) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                
                            callback={_handletype2}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handletype2}
                          />)
                        }

                        </td>
                        <td>보험회사</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>지출통장내역</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type6) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype6}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype6}
                          />)
                        }
                        </td>
                        <td>인터넷뱅킹<br/>엑셀파일</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>자동차세,임대업재산세 <br/>납부내역</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.localtaxdata) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'위택스자료있음'}
                            callback={_handlelocaltaxdata}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'위택스자료조회'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlegetlocaltaxdata}
                          />)}
              
                        </td>
                        <td>위택스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>지역건강보험료 <br/>납부확인서</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type1) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype1}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype1}
                          />)
                        }

                        </td>
                        <td>전화 1577-1000</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>인건비 지급명세서<br/> 신고내역</td>
                        <td>선택</td>
                        <td>

                        {IsEmpty(tax.type9) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype9}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype9}
                          />)
                        }

                        </td>
                        <td>홈택스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>카드사용건별 내역서</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type7) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype7}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype7}
                          />)
                        }
                        </td>
                        <td>각 카드사 홈페이지<br/>엑셀파일</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>기부금영수증</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type10) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype10}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype10}
                          />)
                        }
                        </td>
                        <td>기부단체</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>시설대금 원리금<br/>상환내역서</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type5) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype5}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype5}
                          />)
                        }
                        </td>
                        <td>인터넷뱅킹</td>
                      </tr>

                      <tr style={{height:40}}>
                        <td>특고고용산재보험료<br/>납부내역서</td>
                        <td>선택</td>
                        <td> {IsEmpty(tax.type4) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype4}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype4}
                          />)
                        }</td>
                        <td>고용산재보험토탈서비스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>(공동사업자)소득금액<br/>분배명세서</td>
                        <td>선택</td>
                        <td>{IsEmpty(tax.type11) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype11}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype11}
                          />)
                        }</td>
                        <td>주된사업자</td>
                      </tr>
           
                </table>
                </TableLayer>

                <LabelItem>
                ■ 개별 요청 사항(메모)
                </LabelItem>

                <textarea
                style={{
                  outline: 0,
                  height: "300px",
                  width: "90%",
                  resize: "none",
                  border: "1px solid #9999",
                  margin: "20px 10px",
                }}
                  value={note}
                  onChange={onNoteChange}
                ></textarea>


                <Button
                  buttonText={"세무대리 접수하기"}
                  callback={_handlerequest}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "10px 5%",
                    width: "90%",
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                /> 
                
              </InitContainer>
            ) : (
              <>
              {
                status == 'request' ? (<RequestItem tax={tax} callback={refreshcallback}/>):(    <div style={{ background: "white", height: "700px" }}>
                <div
                  style={{
                    margin: "50px 20px",
                    textAlign: "left",
                    lineHeight: 2,
                  }}
                >
                  <ResultItem item={tax}/>
                </div>
              </div>)
              }
            
              </>
            )}
          </Container>
        )}
      </Mobile>
      <PC>
        {loading == false && (
          <PCContainer>
            {status == "init" ? (
              <InitContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems:'center',
                    padding: "0px 10%",
                    marginTop: "40px",
                  }}
                >
                  <Label
                    content={
                      "세무대리 신청서"
                    }
                    fontsize={"20px"}
                    fontweight={700}
                  />

                  <Label
                    content={
                      "자료 누락 오류 방지를 위해 작성하는 것입니다."
                    }
                    fontsize={"12px"}
                  />


                </div>

                <LabelItem>
                ■ 신청인 정보
                </LabelItem>

                <TableLayer>
                    <table style={{width:'100%'}}>
                      <TableTr>
                        <td style={{background:"#ededed"}}>납세자성명</td>
                        <td colspan ='4'>
                        {tax.username}
                        </td>
             
                        <td style={{background:"#ededed"}}>주민등록상 <br/>주소지</td>
                        <td colspan ='3'>
                          {
                            IsEmpty(tax.addr) == true ? (
                              <MainButton
                              bgcolor={'#e3dfdf'}
                              bordercolor={'#ff0000'}
                              buttonText={'필수입력'}
                              callback={_handleaddr}
                            />
                            ) :(
                              <MainButton
                              buttonText={tax.addr +' '+tax.subaddr}
                              callback={_handleaddr}
                            />
                            )
                          }
                     
                        </td>
                      </TableTr>
                      <TableTr>
                        <td style={{background:"#ededed"}}>주민등록번호</td>
                        <td colspan ='4'>
                        {JuminConvert(tax.jumin)}

                        </td>
                        <td style={{background:"#ededed"}}>계좌번호<br/>(환급)</td>
                        <td colspan ='3'>
                
                        {
                          IsEmpty(tax.bank) == true ? (
                            <MainButton
                            bgcolor={'#e3dfdf'}
                            bordercolor={'#ff0000'}
                            buttonText={'필수입력'}
                            callback={_handlebank}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.bank}
                            callback={_handlebank}
                          />
                          )
                        }

                        </td>
                      </TableTr>
                      <TableTr>
                        <td style={{background:"#ededed"}}>핸드폰번호</td>
                        <td colspan ='4'>
                        {tax.phone}
                        </td>
                        <td style={{background:"#ededed"}}>이메일주소<br/>(선택)</td>
                        <td colspan ='3'>
                  
                           
                        {
                          IsEmpty(tax.email) == true ? (
                            <MainButton
                            bgcolor={'#f6f3f3'}
                            bordercolor={'#ff0000'}
                            buttonText={'선택입력'}
                            callback={_handleemail}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.email}
                            callback={_handleemail}
                          />
                          )
                        }

                        </td>
                      </TableTr>
                      <TableTr>
                        <td colspan ='2' style={{background:"#ededed"}}>홈택스정보</td>
                        <td style={{background:"#ededed"}}>아이디</td>
                        <td colspan ='3' >
               

                        {
                          IsEmpty(tax.hometaxid) == true ? (
                            <MainButton
                            bgcolor={'#f6f3f3'}
                            bordercolor={'#ff0000'}
                            buttonText={'선택입력'}
                            callback={_handlehometaxid}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.hometaxid}
                            callback={_handlehometaxid}
                          />
                          )
                        }

                        </td>
                        <td style={{background:"#ededed"}}>PW</td>
                        <td colspan ='2'>
                        {
                          IsEmpty(tax.hometaxpw) == true ? (
                            <MainButton
                            bgcolor={'#f6f3f3'}
                            bordercolor={'#ff0000'}
                            buttonText={'선택입력'}
                            callback={_handlehometaxpw}
                          />
                          ) :(
                            <MainButton
                            buttonText={tax.hometaxpw}
                            callback={_handlehometaxpw}
                          />
                          )
                        }
                        </td>            
                      </TableTr>
                    </table>

                </TableLayer>

                <LabelItem>
                ■ 수입금액 정보
                </LabelItem>
                <div style={{fontSize:9,paddingLeft:20,display:'flex'}}>(소득종류별 연간 수입금액 확인) (일용직, 퇴직, 양도소득은 종합소득세 신고대상 아님)</div>

                <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed"}} colSpan={2}>소득의 종류</td>
                        <td style={{background:"#ededed", width:80}}>세전 총수입금액</td>
                        <td style={{background:"#ededed"}}>필요서류</td>
                        <td style={{background:"#ededed"}}>비고</td>
                      </TableTr>

                      <tr >
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='3'>홈택스조회</td>
                        <td style={{background:"#ededed"}} >직접입력</td>
                        {
                          agentcheck == true ? (<td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircle onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircleOutline onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                 <MainButton
                                  containerStyle={{height:20}}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                 />
                            
                            ) : (<div>없음</div>)} 
                            </td>):(
                            <td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircleOutline  onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircle onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                  <MainButton
                                  containerStyle={{height:20}}
                                  bgcolor={'#e3dfdf'}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                  />
                            ) : (<div>없음</div>)}           
                            </td>
                          )
                        }

                        <td  rowspan='2'>원천징수영수증</td>
                        <td  rowspan='2'>동의시 당사무소에서 대신 조회해드립니다.</td>
                      </tr>
                      <tr >
                   
                        <td style={{background:"#ededed"}}>근로소득<br/>연금소득</td>
           
       
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed"}}>금융소득<br/>(2천만원이상)</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>
                        <td style={{background:"#ededed"}}>금융소득내역서</td>
                        <td>홈택스 조회 또는 <br/>금융기관에서 발급</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='2'>기타</td>
                        <td style={{background:"#ededed"}}>사업자등록한 분</td>
                        <td>
                        {IsEmpty(tax.businessetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.businessetc) +'원'}
                            callback={_handlebusiness}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlebusiness}
                          />)}
             
                        </td>
                        <td style={{background:"#ededed"}}>부가세신고서/면세현황신고서</td>
                        <td>

                        { vatcount > 0 ? (
                    
                          <MainButton
                          containerStyle={{height:20}}
                          bgcolor={'#e3dfdf'}
                          buttonText={vatcount +"개의 사업장에서 부가가치세확인"}
                          callback={_handlevat}
                          />

                        ) : (<div>없음</div>)}

                        </td>
                      </tr>
                      <tr style={{height:35}}>
                   
                        <td style={{background:"#ededed"}}>주택임대소득</td>
                        <td>

                        {IsEmpty(tax.houseetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.houseetc) +'원'}
                            callback={_handlehouse}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlehouse}
                          />)}

                        </td>
                        <td style={{background:"#ededed"}}>※ 2023년 1월~12월총액</td>
                        <td>세액감면신청 혹은 3주택이상인 분만 별도 신청서 작성</td>
                      </tr>
           
                </table>
                {/* <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed"}} colSpan={2}>소득의 종류</td>
                        <td style={{background:"#ededed", width:80}}>세전 총수입금액</td>
                        <td style={{background:"#ededed"}}>필요서류</td>
                        <td style={{background:"#ededed"}}>비고</td>
                      </TableTr>

                      <tr >
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='3'>원천<br/> 징수</td>
                        <td style={{background:"#ededed"}} >사업(3.3%)<br/>기타 소득</td>
                        {
                          agentcheck == true ? (<td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircle onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircleOutline onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                 <MainButton
                                  containerStyle={{height:20}}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                 />
                            
                            ) : (<div>없음</div>)} 
                            </td>):(
                            <td  rowSpan='2'>세무대리인조회<br/>동의(<IoIosCheckmarkCircleOutline  onClick={_handleagentcheck}  />), 부동의(<IoIosCheckmarkCircle onClick={_handleagentuncheck} />)
                            { IsEmpty(tax.incomedata) == false ? (
                                  <MainButton
                                  containerStyle={{height:20}}
                                  bgcolor={'#e3dfdf'}
                                  buttonText={CommaFormatted(incomemoney)+'원' }
                                  callback={_handleincome}
                                  />
                            ) : (<div>없음</div>)}           
                            </td>
                          )
                        }

                        <td  rowspan='2'>원천징수영수증</td>
                        <td  rowspan='2'>동의시 당사무소에서 대신 조회해드립니다.</td>
                      </tr>
                      <tr >
                   
                        <td style={{background:"#ededed"}}>근로소득<br/>연금소득</td>
           
       
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed"}}>금융소득<br/>(2천만원이상)</td>
                        <td>
                        {IsEmpty(tax.financial) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.financial) +'원'}
                            callback={_handlefinancial}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlefinancial}
                          />)}


                        </td>
                        <td style={{background:"#ededed"}}>금융소득내역서</td>
                        <td>홈택스 조회 또는 <br/>금융기관에서 발급</td>
                      </tr>
                      <tr style={{height:35}}>
                        <td style={{background:"#ededed",minWidth: 20}} rowspan='2'>기타</td>
                        <td style={{background:"#ededed"}}>사업자등록한 분</td>
                        <td>
                        {IsEmpty(tax.businessetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.businessetc) +'원'}
                            callback={_handlebusiness}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlebusiness}
                          />)}
             
                        </td>
                        <td style={{background:"#ededed"}}>부가세신고서/면세현황신고서</td>
                        <td>

                        { vatcount > 0 ? (
                    
                          <MainButton
                          containerStyle={{height:20}}
                          bgcolor={'#e3dfdf'}
                          buttonText={vatcount +"개의 사업장에서 부가가치세확인"}
                          callback={_handlevat}
                          />

                        ) : (<div>없음</div>)}

                        </td>
                      </tr>
                      <tr style={{height:35}}>
                   
                        <td style={{background:"#ededed"}}>주택임대소득</td>
                        <td>

                        {IsEmpty(tax.houseetc) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={CommaFormatted(tax.houseetc) +'원'}
                            callback={_handlehouse}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlehouse}
                          />)}

                        </td>
                        <td style={{background:"#ededed"}}>※ 2023년 1월~12월총액</td>
                        <td>세액감면신청 혹은 3주택이상인 분만 별도 신청서 작성</td>
                      </tr>
           
                </table> */}
                </TableLayer>

                <LabelItem>
                ■ 공제대상 부양가족만 기재
                </LabelItem>
                <div style={{fontSize:9,paddingLeft:20, display:"flex", flexDirection:"row", justifyContent:'space-between', alignItems:"center"}}>
                  <div style={{ display:"flex", flexDirection:"row", justifyContent:'center', alignItems:"center"}}>
                  {
                    womancheck == true ? (<>※ 부녀자공제 신청 여(<IoIosCheckmarkCircle  onClick={_handlewomancheck}  />), 부(<IoIosCheckmarkCircleOutline onClick={_handlewomanuncheck} />)</>):(
                      <> ※ 부녀자공제 신청 여(<IoIosCheckmarkCircleOutline  onClick={_handlewomancheck}  />), 부(<IoIosCheckmarkCircle onClick={_handlewomanuncheck} />)</>

                    )
                  }
                  <div style={{width:20}}></div>

                  {
                    singlecheck == true ? (<>※ 한부모공제 신청 여(<IoIosCheckmarkCircle  onClick={_handlesinglecheck}  />), 부(<IoIosCheckmarkCircleOutline onClick={_handlesingleuncheck} />)</>):(
                      <> ※ 한부모공제 신청 여(<IoIosCheckmarkCircleOutline  onClick={_handlesinglecheck}  />), 부(<IoIosCheckmarkCircle onClick={_handlesingleuncheck} />)</>

                    )
                  }

                  </div>
 
                  <Button
                    icon={"useradd"}
                    buttonText={"공제대상	추가"}
                    callback={handleMemberAdd}
                    containerStyle={{
                      margin: "0px 10px 0px",
                      width: "60px",
                      height: 20,
                      fontSize: 10,
                      fontWeight: 300,
                      backgroundColor: "#ededed",
                      color: "#757575",
                      borderRadius: "5px",
                      border : 'none',
                      display: "flex",
                      boxShadow: '2px 1px 1px #e2dfdf',
                      justifyContent: "space-around",
                    }}
                  />

                </div>
                <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed", width:100}}>성명</td>
                        <td style={{background:"#ededed", width:100}}>주민등록번호</td>
                        <td style={{background:"#ededed"}}>본인과의관계</td>
                        <td style={{background:"#ededed"}}>장애여부</td>
                      </TableTr>

                      {
                        etchumanitems.map((data,index)=>(
                          <tr style={{height:25}}>
                          <td>{data.name}</td>
                          <td>{JuminConvert(data.jumin)}</td>
                          <td>{data.relation}</td>
                          <td>{data.disable}</td>
                        </tr>
                        ))
                      }

                      {
                        10  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }

                      {
                        9  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        8  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        7  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        6  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        5  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        4  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
                      {
                        3  - etchumanitems.length > 0 &&<tr style={{height:25}}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      }
              



           
                </table>
                </TableLayer>
                <div style={{fontSize:9, textAlign:"left",paddingLeft:15}}>
                * 공제대상 부양가족 : 연간소득 100만 원 이하의 1963.12.31.이전 출생 양가 부모(동거무관) 및 2003.01.01.이후 출생 직계비속과 형제
                </div>
                <div style={{fontSize:9, textAlign:"left",paddingLeft:15, paddingTop:10}}>
                * 부녀자공제: 배우자있는여성또는부양가족있는여성세대주로연소득3천만원이하 *한부모공제: 배우자없고직계비속만있는분
                </div>

                <LabelItem>
                ■ 연말정산간소화 자료, 사업성 필요경비 등 개인 서류
                </LabelItem>


                <TableLayer>
                <table style={{width:'100%'}}>
                      <TableTr style={{height:20}}>
                        <td style={{background:"#ededed", width:100}}>서류종류</td>
                        <td style={{background:"#ededed", width:30}}>구분</td>
                        <td style={{background:"#ededed", width:90}}>첨부여부</td>
                        <td style={{background:"#ededed"}}>준비경로</td>
                      </TableTr>
                      <tr style={{height:40}}>
                        <td>연말정산간소화자료</td>
                        <td><div style={{fontFamily:'Pretendard-Bold'}}>필수</div></td>
                        <td>
                        {IsEmpty(tax.yeardata.info) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'홈택스자료있음'}
                            callback={_handleyeardata}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handleyeardata}
                          />)}
                        </td>
                        <td>국세청 홈택스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>차량보험증권</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type2) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                
                            callback={_handletype2}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handletype2}
                          />)
                        }

                        </td>
                        <td>보험회사</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>지출통장내역</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type6) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype6}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype6}
                          />)
                        }
                        </td>
                        <td>인터넷뱅킹<br/>엑셀파일</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>자동차세,임대업재산세 <br/>납부내역</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.localtaxdata) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'위택스자료있음'}
                            callback={_handlelocaltaxdata}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'위택스자료조회'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}
                            callback={_handlegetlocaltaxdata}
                          />)}
              
                        </td>
                        <td>위택스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>지역건강보험료 <br/>납부확인서</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type1) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype1}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype1}
                          />)
                        }

                        </td>
                        <td>전화 1577-1000</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>인건비 지급명세서<br/> 신고내역</td>
                        <td>선택</td>
                        <td>

                        {IsEmpty(tax.type9) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype9}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype9}
                          />)
                        }

                        </td>
                        <td>홈택스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>카드사용건별 내역서</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type7) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype7}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype7}
                          />)
                        }
                        </td>
                        <td>각 카드사 홈페이지<br/>엑셀파일</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>기부금영수증</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type10) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype10}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype10}
                          />)
                        }
                        </td>
                        <td>기부단체</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>시설대금 원리금<br/>상환내역서</td>
                        <td>선택</td>
                        <td>
                        {IsEmpty(tax.type5) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype5}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype5}
                          />)
                        }
                        </td>
                        <td>인터넷뱅킹</td>
                      </tr>

                      <tr style={{height:40}}>
                        <td>특고고용산재보험료<br/>납부내역서</td>
                        <td>선택</td>
                        <td> {IsEmpty(tax.type4) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype4}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype4}
                          />)
                        }</td>
                        <td>고용산재보험토탈서비스</td>
                      </tr>
                      <tr style={{height:40}}>
                        <td>(공동사업자)소득금액<br/>분배명세서</td>
                        <td>선택</td>
                        <td>{IsEmpty(tax.type11) == false ? (<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료있음'}
                            callback={_handletype11}
                          />):(<MainButton
                            containerStyle={{height:20}}
                            buttonText={'자료없음'}
                            bgcolor={'#fff'}
                            bordercolor={'#ff0000'}    
                            callback={_handletype11}
                          />)
                        }</td>
                        <td>주된사업자</td>
                      </tr>
           
                </table>
                </TableLayer>

                <LabelItem>
                ■ 개별 요청 사항(메모)
                </LabelItem>

                <textarea
                style={{
                  outline: 0,
                  height: "300px",
                  width: "90%",
                  resize: "none",
                  border: "1px solid #9999",
                  margin: "20px 10px",
                }}
                  value={note}
                  onChange={onNoteChange}
                ></textarea>


                <Button
                  buttonText={"세무대리 접수하기"}
                  callback={_handlerequest}
                  containerStyle={{
                    backgroundColor: colors.mainbuttoncolor,
                    color: "#fff",
                    margin: "10px 5%",
                    width: "90%",
                    height: 40,
                    fontSize: 17,
                    fontWeight: 700,
                    borderRadius: "10px",
                  }}
                /> 
                
              </InitContainer>
            ) : (
              <>
              {
                status == 'request' ? (<RequestItem tax={tax} callback={refreshcallback}/>):(    <div style={{ background: "white", height: "700px" }}>
                <div
                  style={{
                    margin: "50px 20px",
                    textAlign: "left",
                    lineHeight: 2,
                  }}
                >
                  <ResultItem item={tax}/>
                </div>
              </div>)
              }
            
              </>
            )}
          </PCContainer>
        )}
      </PC>
    </>
  );
};

export default NewHomecontainer;
