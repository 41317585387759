import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_tax, get_tax2, get_userInfoForUID, update_userexcelfile, update_userfile, uploadExcel, uploadImage } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import { imageDB } from "../utility/imageData";
import Image from "../common/Image";

import { FaCheckCircle } from "react-icons/fa";
import { setRef } from "@mui/material";
import Info from "../common/Info";
import YearItem from "../components/YearItem";
import IncomeItem from "../components/IncomeItem";
import VatItem from "../components/VatItem";
import { IsEmpty } from "../utility/common";
import WidthhodlingItem from "../components/WithholdingItem";
import LocalTaxItem from "../components/LocalTaxItem";
import { AttachType } from "../utility/contentDefine";
import { SiMicrosoftexcel } from "react-icons/si";
import Loading from "../components/Loading";

const Container = styled.div`
  padding: 80px 20px;
  background: white;

`;
 const PCContainer = styled.div`
   width: 27%;
   margin: 0px 37%;
   background: white;
   padding-top: 80px;
   height: 600px;

   border: 1px solid;
 `;

const ButtonLayer = styled.div`
  display: flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items:center;
  width:100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
`;

 
const ImageItem = styled.div`
  width: 100%;
  margin: 20px 0px;
  display : flex;
  flex-direction : row;
  align-items:center;
`;


const ExcelItem = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 5px;
`;


const ExcelInfocontainer = ({type, taxid}) => {


  const [items, setItems] = useState([]);
  
  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);
  const [refresh, setRefresh] = useState(1);
  const [reload, setReload] = useState(0);
  const [upload, setUpload] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {

  }, [refresh])

  useEffect(() => {

    async function FetchData() {
      const tax_id = taxid;
      const itemsTmp = await get_tax2({ tax_id });
       
      if (type == AttachType.TYPE6) {
        setItems(itemsTmp.type6);
      } else if (type == AttachType.TYPE7) {
        setItems(itemsTmp.type7);
      } 
      console.log("items", itemsTmp.type6);
    } 
    FetchData();
  }, []);
  

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  const fileInput = useRef();

  const handleUploadClick = (e) => {
    fileInput.current.click();
  };



  const handlefileuploadChange = async (e) => {
    let filename = "";
    const file = e.target.files[0];
    filename = file.name;

    if (!ImagefileExtensionValid(filename)) {
      window.alert("업로드 대상 파일의 확장자는 xlsx 만 가능 합니다");
      return;
    }

    setLoad(false);

    var p1 = new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let img = reader.result;
        resolve(img);
      };
    });
    const getRandom = () => Math.random();
    const email = getRandom();

    p1.then(async (result) => {
      const uri = result;
      console.log("uri", uri);

      const URL = await ExcelUpload(uri, email);
      console.log("image", URL);
      let exceldata = {
        path: "",
        name: "",
      };
      exceldata.path = URL;
      exceldata.name = filename;
      items.push(exceldata);

      setItems(items);
    
      const tax_id = taxid;
      const itemsdata = items;
      console.log("upload", tax_id, type, itemsdata);
      update_userexcelfile({ tax_id, type, itemsdata });

      setLoad(false);
      setReload((reload) => reload + 1);
    });
  };

  const ExcelUpload = async (data, data2) => {
    const uri = data;
    const email = data2;
    const URL = await uploadExcel({ uri, email });
    return URL;
  };
  const ALLOW_IMAGE_FILE_EXTENSION = "xlsx";

  const ImagefileExtensionValid = (name) => {

    return true;
    // const extention = removeFileName(name);

    // if (
    //   ALLOW_IMAGE_FILE_EXTENSION.indexOf(extention) <= -1 ||
    //   extention == ""
    // ) {
    //   return false;
    // }
    // return true;
  };
  const removeFileName = (originalFileName) => {
    const lastIndex = originalFileName.lastIndexOf(".");

    if (lastIndex < 0) {
      return "";
    }
    return originalFileName.substring(lastIndex + 1).toLowerCase();
  };

  const handlePrev = () =>{
    navigate(-1);
  }


  useEffect(() => {
    setItems(items);
    setLoad(load);
    
  }, [reload])
  
  
  return (
    <>
      {load == true ? (
        <Loading />
      ) : (
        <>
          {" "}
          <Mobile>
            <Container height={height}>
              <>
              {type == AttachType.TYPE5 && (
                  <Info
                    content={
                      "시설대금 원리금 상환내역 확인가능한  파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE6 && (
                  <Info
                    content={
                      "지출통장내역 확인가능한  파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE7 && (
                  <Info
                    content={
                      "카드 사용건별 확인가능한파일을 엑셀파일로 첨부해 주세요  개별 요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {/* <ButtonLayer>
                  <Button
                    className="button"
                    icon={"add"}
                    buttonText={""}
                    callback={handleUploadClick}
                    containerStyle={{
                      marginTop: "20px",
                      width: "80px",
                      height: 25,
                      fontSize: 14,
                      fontWeight: 300,
                      color: "#000",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </ButtonLayer> */}

                <ButtonLayer>
                  <Button
                      buttonText={"뒤로 가기"}
                      callback={handlePrev}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px",
                        width: "100%",
                        height: 30,
                        fontSize: 15,
                        fontFamily:"Pretendard-Regular",
                        borderRadius: "5px",
                    }} 
                    />

                  <Button
                      buttonText={"자료 추가하기"}
                      callback={handleUploadClick}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px",
                        width: "100%",
                        height: 30,
                        fontSize: 15,
                        fontFamily:"Pretendard-Regular",
                        borderRadius: "5px",
                    }} 
                />
                </ButtonLayer>


                <input
                  type="file"
                  ref={fileInput}
                  onChange={handlefileuploadChange}
                  style={{ display: "none" }}
                />

                <Content>
                  {items.length > 0 && (
                    <table
                      style={{ marginTop: 5, fontSize: 14, width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">순번</th>
                          <th scope="col">내용</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((data, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <ExcelItem>
                                <SiMicrosoftexcel size={20} />

                                <a
                                  target={"_blank"}
                                  href={data.path}
                                  style={{ marginLeft: 10 }}
                                >
                                  <div
                                    style={{
                                      color: "#000",
                                      textDecoration: "underline",
                                      fontSize: 13,
                                    }}
                                  >
                                    {data.name}
                                  </div>
                                </a>
                              </ExcelItem>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Content>
              </>
            </Container>
          </Mobile>
          <PC>
            <PCContainer height={height}>
              <>

                {type == AttachType.TYPE5 && (
                  <Info
                    content={
                      "시설대금 원리금 상환내역 확인가능한  파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}

                {type == AttachType.TYPE6 && (
                  <Info
                    content={
                      "지출통장내역 확인가능한  파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE7 && (
                  <Info
                    content={
                      "카드 사용건별 확인가능한파일을 엑셀파일로 첨부해 주세요  개별 요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                 <ButtonLayer>
                  <Button
                      buttonText={"뒤로 가기"}
                      callback={handlePrev}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px",
                        width: "100%",
                        height: 30,
                        fontSize: 15,
                        fontFamily:"Pretendard-Regular",
                        borderRadius: "5px",
                    }} 
                    />

                  <Button
                      buttonText={"자료 추가하기"}
                      callback={handleUploadClick}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px",
                        width: "100%",
                        height: 30,
                        fontSize: 15,
                        fontFamily:"Pretendard-Regular",
                        borderRadius: "5px",
                    }} 
                />
                </ButtonLayer>

                <input
                  type="file"
                  ref={fileInput}
                  onChange={handlefileuploadChange}
                  style={{ display: "none" }}
                />

                <Content>
                  {items.length > 0 && (
                    <table
                      style={{ marginTop: 5, fontSize: 14, width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">순번</th>
                          <th scope="col">내용</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((data, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <ExcelItem>
                                <SiMicrosoftexcel size={20} />

                                <a
                                  target={"_blank"}
                                  href={data.path}
                                  style={{ marginLeft: 10 }}
                                >
                                  <div
                                    style={{
                                      color: "#000",
                                      textDecoration: "underline",
                                      fontSize: 13,
                                    }}
                                  >
                                    {data.name}
                                  </div>
                                </a>
                              </ExcelItem>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Content>
              </>
            </PCContainer>
          </PC>
        </>
      )}
    </>
  );
};

export default ExcelInfocontainer;
