import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import {
  HashRouter,
  Route,
  Redirect,
  BrowserRouter,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Swipe from "../common/Swipe";

import styled from "styled-components";

import Guide from "../components/Guide";
import Swipe2 from "../common/Swipe2";
import Button from "../common/Button";
import { colors, theme } from "../theme/theme";
import InputItem from "../components/InputItem";
import InputReturn from "../components/InputReturn";
import Next from "../components/Next";
import { Mobile, PC } from "../components/Responsive";
import useDimension from "../common/UseDimension";
import SelectItem from "../components/SelectItem";
import { UserContext } from "../context/User";
import { get_tax, get_tax2, get_userInfoForUID, update_userfile, uploadImage } from "../service/UserService";
import { BiCalendarEdit } from "react-icons/bi";
import { FcInfo } from "react-icons/fc";
import { imageDB } from "../utility/imageData";
import Image from "../common/Image";

import { FaCheckCircle } from "react-icons/fa";
import { setRef } from "@mui/material";
import Info from "../common/Info";
import YearItem from "../components/YearItem";
import IncomeItem from "../components/IncomeItem";
import VatItem from "../components/VatItem";
import { IsEmpty } from "../utility/common";
import WidthhodlingItem from "../components/WithholdingItem";
import LocalTaxItem from "../components/LocalTaxItem";
import { AttachType } from "../utility/contentDefine";
import Loading from "../components/Loading";
import LoadingEx from "../components/LoadingEx";

const Container = styled.div`
  padding: 80px 20px;
  background: white;

`;
 const PCContainer = styled.div`
 width: 27%;
 margin: 0px 37%;
   background: white;
   padding-top: 80px;
   height: 100%;
   height: 600px;
   border: 1px solid;
 `;

const ButtonLayer = styled.div`
  display: flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items:center;
  width:100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
`;

 
const ImageItem = styled.div`

  margin: 10px;

`;


const ImageInfocontainer = ({type, taxid}) => {


  const navigate = useNavigate();
  
  const {user, dispatch2 } = useContext(UserContext);
  const [height, setHeight] = useState(window.innerHeight);
  const [refresh, setRefresh] = useState(1);
  const [reload, setReload] = useState(0);
  const [upload, setUpload] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  const [load, setLoad] = useState(false);
  

  useEffect(() => {
    setImageUrls(imageUrls);
    setLoad(load);
  }, [reload]);

  
  useEffect(() => {

  }, [refresh])

  useEffect(() => {

    async function FetchData() {
      const tax_id = taxid;
      const items = await get_tax2({ tax_id });

      if (type == AttachType.TYPE1) {
        setImageUrls(items.type1);
      } else if (type == AttachType.TYPE2) {
        setImageUrls(items.type2);
      } else if (type == AttachType.TYPE3) {
        setImageUrls(items.type3);
      } else if (type == AttachType.TYPE4) {
        setImageUrls(items.type4);
      } else if (type == AttachType.TYPE5) {
        setImageUrls(items.type5);
      } else if (type == AttachType.TYPE6) {
        setImageUrls(items.type6);
      } else if (type == AttachType.TYPE7) {
        setImageUrls(items.type7);
      } else if (type == AttachType.TYPE8) {
        setImageUrls(items.type8);
      } else if (type == AttachType.TYPE9) {
        setImageUrls(items.type9);
      } else if (type == AttachType.TYPE10) {
        setImageUrls(items.type10);
      } 
    } 
    FetchData();
  }, []);
  

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  const fileInput = useRef();

  const handleUploadClick = (e) => {
    fileInput.current.click();
  };

  const ALLOW_IMAGE_FILE_EXTENSION = "jpg,jpeg,png,bmp";

  const ImagefileExtensionValid = (name) => {
    return true;
    // const extention = removeFileName(name);

    // if (
    //   ALLOW_IMAGE_FILE_EXTENSION.indexOf(extention) <= -1 ||
    //   extention == ""
    // ) {
    //   return false;
    // }
    // return true;
  };
  const removeFileName = (originalFileName) => {
    const lastIndex = originalFileName.lastIndexOf(".");

    if (lastIndex < 0) {
      return "";
    }
    return originalFileName.substring(lastIndex + 1).toLowerCase();
  };

  const ImageUpload = async (data, data2) => {
    const uri = data;
    const email = data2;
    const URL = await uploadImage({ uri, email });
    return URL;
  };
  
  
  const handlefileuploadChange = async (e) => {
    let filename = "";
    const file = e.target.files[0];
    filename = file.name;
    let imageurls = imageUrls;

    if (!ImagefileExtensionValid(filename)) {
      window.alert(
        "업로드 대상 파일의 확장자는 bmp, jpg, jpeg, png 만 가능 합니다"
      );
      return;
    }

    setLoad(true);

    var p1 = new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let img = reader.result;
        resolve(img);
      };
    });
    const getRandom = () => Math.random();
    const email = getRandom();

    p1.then(async (result) => {
      const uri = result;
      console.log("uri", uri);

      const URL = await ImageUpload(uri, email);
      console.log("image", URL);
      imageurls.push(URL);

      setImageUrls(imageurls);

      const tax_id = taxid;
      const imageurl = imageUrls;
      update_userfile({ tax_id, type, imageurl });
      setLoad(false);
      
      setReload((reload) => reload + 1);
    });
  };


  const handlePrev = () =>{
    navigate(-1);
  }



  
  
  return (
    <>
      {load == true ? (
        <LoadingEx />
      ) : (
        <div>
          <Mobile>
            <Container height={height}>
              <>
                {type == AttachType.TYPE1 && (
                  <Info
                    content={
                      "지역 건보료 납부 내역이 확인 가능한 이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE2 && (
                  <Info
                    content={
                      "차량등록증이나 자동차보험증권 이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE3 && (
                  <Info
                    content={
                      "자동차 리스계약서나 리스스케줄표가 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE4 && (
                  <Info
                    content={
                      "특고 고용산재보험 납부내역이 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE5 && (
                  <Info
                    content={
                      "임대업을 영위하는 사업자이시면 시설대금 원리금 상환내역서 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE6 && (
                  <Info
                    content={
                      "지출통장내역 확인가능한  파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE7 && (
                  <Info
                    content={
                      "카드 사용건별 확인가능한파일을 엑셀파일로 첨부해 주세요  개별 요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}

                {type == AttachType.TYPE9 && (
                  <Info
                    content={
                      "인건비 납부가 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE10 && (
                  <Info
                    content={
                      "기부금 납부가 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE11 && (
                  <Info
                    content={
                      "소득금액분배명서세서 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                <ButtonLayer>
  
                    <Button
                      buttonText={"뒤로 가기"}
                      callback={handlePrev}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px",
                        width: "100%",
                        height: 30,
                        fontSize: 15,
                        fontFamily:"Pretendard-Regular",
                        borderRadius: "5px",
                    }} 
                    />

                  <Button
                      buttonText={"자료 추가하기"}
                      callback={handleUploadClick}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px",
                        width: "100%",
                        height: 30,
                        fontSize: 15,
                        fontFamily:"Pretendard-Regular",
                        borderRadius: "5px",
                    }}
                  /> 
                    
                </ButtonLayer>

                <input
                  type="file"
                  ref={fileInput}
                  onChange={handlefileuploadChange}
                  style={{ display: "none" }}
                />

                <Content>
                  {imageUrls.map((data, index) => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ImageItem>
                        <img
                          src={data}
                          style={{ height: "120px", width: "120px" }}
                        />
                      </ImageItem>
                    </div>
                  ))}
                </Content>
              </>
            </Container>
          </Mobile>
          <PC>
            <PCContainer height={height}>
              <>
                {type == AttachType.TYPE1 && (
                  <Info
                    content={
                      "지역 건보료 납부 내역이 확인 가능한 이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE2 && (
                  <Info
                    content={
                      "차량등록증이나 자동차보험증권 이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE3 && (
                  <Info
                    content={
                      "자동차 리스계약서나 리스스케줄표가 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE4 && (
                  <Info
                    content={
                      "특고 고용산재보험 납부내역이 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE5 && (
                  <Info
                    content={
                      "임대업을 영위하는 사업자이시면 시설대금 원리금 상환내역서 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE6 && (
                  <Info
                    content={
                      "지출통장내역 확인가능한  파일을 엑셀파일로 첨부해 주세요  개별요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                {type == AttachType.TYPE7 && (
                  <Info
                    content={
                      "카드 사용건별 확인가능한파일을 엑셀파일로 첨부해 주세요  개별 요청자만 첨부해주세요. 추가하고자 할 파일이 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}

               {type == AttachType.TYPE9 && (
                  <Info
                    content={
                      "인건비 납부가 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}

                {type == AttachType.TYPE10 && (
                  <Info
                    content={
                      "기부금 납부가 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}

                {type == AttachType.TYPE11 && (
                  <Info
                    content={
                      "소득금액분배명서세서 확인가능한  이미지 파일이나 사진을 첨부해 주세요. 추가하고자 할 이미지가 있을경우 자료추가하기 버튼을 눌러주세요"
                    }
                  />
                )}
                <ButtonLayer>
  

                  <Button
                      buttonText={"뒤로 가기"}
                      callback={handlePrev}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px 10%",
                        width: "80%",
                        height: 40,
                        fontSize: 15,
                        fontWeight: 700,
                        borderRadius: "10px",
                    }} 
                    />

                  <Button
                      buttonText={"자료 추가하기"}
                      callback={handleUploadClick}
                      containerStyle={{
                        backgroundColor: colors.mainbuttoncolor,
                        color: "#fff",
                        margin: "10px 10%",
                        width: "80%",
                        height: 40,
                        fontSize: 15,
                        fontWeight: 700,
                        borderRadius: "10px",
                    }} 
                />
                </ButtonLayer>

                <input
                  type="file"
                  ref={fileInput}
                  onChange={handlefileuploadChange}
                  style={{ display: "none" }}
                />

                <Content>
                  {imageUrls.map((data, index) => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ImageItem>
                        <img
                          src={data}
                          style={{ height: "120px", width: "120px" }}
                        />
                      </ImageItem>
                    </div>
                  ))}
                </Content>
              </>
            </PCContainer>
          </PC>
        </div>
      )}
    </>
  );
};

export default ImageInfocontainer;
